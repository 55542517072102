import React from 'react'

import AdminContent from '../admin-content'
import DashboardRequestStats from '../DashboardRequestStats'
import Heading from '../../ui/Heading'

const Dashboard = () => (
  <AdminContent>
    <>
      <DashboardRequestStats />
      <Heading>Nyttige lenker</Heading>
      <ul>
        <li>
          <a
            href="https://trello.com/b/wmzUooFM/utvikling"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trello
          </a>
        </li>
        <li>
          <a
            href="https://dashboard.heroku.com/teams/gigplanet/apps"
            target="_blank"
            rel="noopener noreferrer"
          >
            Heroku
          </a>
        </li>
        <li>
          <a
            href=" https://app.intercom.io/a/apps/tm5w6vcp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom
          </a>
        </li>

        {/*
        <li>
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </li>
        */}
      </ul>
    </>
  </AdminContent>
)

export default Dashboard
