import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from 'recharts'
import moment from 'moment'

import useAPI from '../../hooks/useAPI'
import PageLoader from '../page-loader'
import { printCurrency } from '../../helpers/currency'
import Heading from '../../ui/Heading'
import { ChartWrapper } from './style'

const DashboardRequestStats = React.memo(() => {
  const [{ data, isLoading, hasLoaded }, fetchData] = useAPI('/api/v1/admin/requestStats')

  if (isLoading && !hasLoaded) {
    return <PageLoader text="Laster graf" />
  }
  const formattedData = (data || []).map(e => ({
    date: moment(e.from).format('MMM YYYY'),
    gigs: e.gigs,
    quotesAccepted: e.quotesAccepted,
    requests: e.requests,
    revenue: e.revenue,
    sales: e.sales,
  }))

  return (
    <>
      <Heading>Forespørsler og spillejobber</Heading>
      <ChartWrapper paddingBottom>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="gigs"
              name="Gjennomførte spillejobber"
              stroke="#EC7063"
            />
            <Line
              type="monotone"
              dataKey="quotesAccepted"
              name="Avtalte spillejobber"
              stroke="#AF7AC5"
            />
            <Line type="monotone" dataKey="requests" name="Nye forespørsler" stroke="#5499C7" />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>

      <Heading>Omsetning</Heading>
      <ChartWrapper>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={value => printCurrency(value)} />
            <Legend />
            <Bar dataKey="revenue" name="Omsetning" fill="#EC7063" />
            <Bar dataKey="sales" name="Signerte kontrakter" fill="#AF7AC5" />
          </BarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </>
  )
})

DashboardRequestStats.propTypes = {}
DashboardRequestStats.defaultProps = {}

export default DashboardRequestStats
