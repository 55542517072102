import styled, { css } from 'styled-components'

export const ChartWrapper = styled.div`
  padding: 25px 0 0 0;

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 25px;
    `};
`
