import React from 'react'
import PropTypes from 'prop-types'

import { StyledPageLoader } from './style'

import Loader from '../../ui/loader'

const PageLoader = ({ text }) => (
  <StyledPageLoader>
    <Loader text={text} />
  </StyledPageLoader>
)

PageLoader.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PageLoader
