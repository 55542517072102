import React from 'react'

import MainLayout from '../../components/main-layout'
import AdminHeader from '../../components/admin-header'
import Dashboard from '../../components/dashboard'
import useCurrentUser from '../../hooks/useCurrentUser'

const DashboardView = React.memo(() => {
  const currentUser = useCurrentUser()
  return (
    <MainLayout currentUser={currentUser}>
      <>
        <AdminHeader>Dashboard</AdminHeader>
        <Dashboard currentUser={currentUser} />
      </>
    </MainLayout>
  )
})

export default DashboardView
