import React from 'react'

import DashboardView from '../views/dashboard'
import MetaData from '../components/metaData'

const DashboardPage = () => (
  <>
    <MetaData title="Dashboard" />
    <DashboardView />
  </>
)

export default DashboardPage
