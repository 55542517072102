import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const StyledPageLoader = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`
