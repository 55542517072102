// global isNaN */
/* eslint-disable import/prefer-default-export */
export const printCurrency = input => {
  const sign = 'kr'
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(input)) {
    return `${sign} 0`
  }

  const formattedOutput = []
  const pieces = parseFloat(input)
    .toFixed(0)
    .split('')
  const reversedPieces = pieces.reverse()
  reversedPieces.forEach((digit, index) => {
    formattedOutput.push(digit)
    if ((index + 1) % 3 === 0 && index < reversedPieces.length - 1) {
      formattedOutput.push(' ')
    }
  })
  return `${sign} ${formattedOutput.reverse().join('')}`
}
